/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { NewspaperIcon, PhoneIcon, SupportIcon } from '@heroicons/react/outline'
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'


const faqs = [
  {
    question: "Welche Organisationen bekommen eure Dienstleistungen kostenfrei?",
    answer:
      "Alle Organisationen, Vereine und Unternehmen die gemeinnützig, ehrenamtlich und non-Profit arbeiten.",
  },
  {
    question: "Wie kann ich mich dafür bewerben?",
    answer:
      "Schreib uns einfach, wir beißen nicht. ;-)",
  },
  {
    question: "Wieso tut ihr das?",
    answer:
      "Um das Ehrenamt zu fördern. Das kommt unserer Meinung nach in Deutschland viel zu kurz. Jeder sollte ehrenamtlich tätig sein.",
  },
  {
    question: "Kann ich dabei helfen?",
    answer:
      "Na klar. Erzähl es allen, die du kennst. Denn eine große Reichweite ist ganz wichtig beim Thema Ehrenamt.",
  },
  // More questions...
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Ehrenamt() {
  return (
    <Layout>
    <Helmet title="Ehrenamt - Wuppertal360 - 360° Panoramen und virtuelle Rundgänge" />
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Ehrenamtlich fürs Ehrenamt</h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
    </Layout>
  )
}
